// custom typefaces
import "./src/css/general-sans.css";

// normalize CSS across browsers
import "./src/css/normalize.css";

// custom CSS styles
// import "./src/css/style.css";
import "./src/css/global.css";

// Import Swiper styles
import 'swiper/css';
// import "swiper/css/mousewheel";
// import "swiper/css/effect-fade";
import "./src/css/swiper-customizations.css";

import "./src/css/sal-0.8.5.css";


// Gatsby Lifecycle Methods

export const onRouteUpdate = ({ location }) => {
  // console.log('Location: ', location);
  // console.log('From onRouteUpdate!');
  window.sal();
  if ( document.querySelector('.grid') ) {
    // console.log('Grid Exists');
    new window.Colcade( '.grid', {
      columns: '.grid-col',
      items: '.grid-item'
    });
  }
};